import { ElMessage } from 'element-plus'


/**
 * 复制链接
 * @param data
 * @returns {string}
 */
export const copyShareLink = (url) => {
  copy(url,'复制成功！赶紧分享给好友吧！');
}

/**
 * 复制文字或链接
 */
export function copy(context,msg) {
  console.log("已复制文字或链接:"+context)
  let input = document.createElement("input");
  document.body.appendChild(input);
  input.setAttribute("value", context);
  input.select();
  document.execCommand("copy");
  document.body.removeChild(input);
  ElMessage({
    type: 'success',
    message: msg,
    offset: 360
  });
}

function _isMobile(){
  const reg = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i;
  return navigator.userAgent.match(reg) != null;

}

//导出公共方法
export default {
  isMobile(){
    return  _isMobile();
  },
  copyMe(context,msg){
    return copy(context,msg);
  },

}


